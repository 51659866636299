import { collection, getDocs } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../firebase-config";
import { useTranslation } from "react-i18next";
function SelectPackPayment({ setShowPackages, handleHidePack }) {
  const [iPackages, setIPackages] = useState([]);
  const [showPay, setShowPay] = useState(false);
  const { t: translate } = useTranslation();
  const [category, setCategory] = useState(translate("eckpk"));
  const [isShown, setIsShown] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState([]);
  const [totalPrice, setTotalPrice] = useState();
  const [showOptions, setShowOptions] = useState(false);

  const packagesCollectionRef = collection(db, "internetPack");

  useEffect(() => {
    const getPackages = async () => {
      try {
        // Check local storage for saved data and timestamp
        const savedData = JSON.parse(localStorage.getItem("packagesData"));
        const savedTimestamp = localStorage.getItem("packagesTimestamp");

        // Check if data is older than one week (7 days * 24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
        const oneWeek = 7 * 24 * 60 * 60 * 1000;
        const isDataExpired =
          !savedTimestamp ||
          Date.now() - new Date(savedTimestamp).getTime() > oneWeek;

        if (savedData && !isDataExpired) {
          // Use saved data if it's still valid
          setIPackages(savedData);
        } else {
          // Fetch new data from API
          const querySnapshot = await getDocs(packagesCollectionRef);
          const data = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));

          // Save the new data and timestamp in local storage
          localStorage.setItem("packagesData", JSON.stringify(data));
          localStorage.setItem("packagesTimestamp", new Date().toISOString());

          // Update state with the new data
          setIPackages(data);
        }
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    };

    getPackages();
  }, []);

  const hidePack = (plan) => {
    setTotalPrice();
    setSelectedPlan(plan);
    setIsShown(!isShown);
    setShowOptions(false);
    handleHidePack();
    setShowPay(false);
    setShowPackages(false);
  };
  const handleFilter = (value) => {
    setCategory(value);
  };

  const handlePackSelect = (price) => {
    setTotalPrice(price);
    setShowOptions(true);

    const packageData = {
      speed: `${selectedPlan.package_name} Mbps`,
      price: price,
      type: selectedPlan.type,
    };

    localStorage.setItem("selected_package", JSON.stringify(packageData));
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center">
        <section
          id="pop-up-screen"
          style={{
            zIndex: 9,
            backdropFilter: "blur(3px) saturate(180%)",
            backgroundColor: "#ffffff1a",
          }}
          className={`services-section overflow-y-scroll spad cops w-[100%] flex felx-col items-center justify-center fixed top-0 h-[100vh] ${
            isShown ? "" : "none"
          }`}
        >
          <div className="card-container w-4/5 md:w-3/4 lg:w-2/4 overflow-y-scroll mt-[5rem] md:mt-[2px]">
            <div className="card w-full px-2">
              <button
                type="button"
                onClick={hidePack}
                className="dismiss w-full flex flex-col items-end justify-center "
              >
                <h1 className="flex flex-col items-center justify-center rounded-[7px] font-bold text-md border-2 border-r[#D1D5DB] w-[30px] h-[30px] mt-2 py-2 px-3">
                  x
                </h1>
              </button>
              <>
                {!showPay && (
                  <div
                    id="pack_card"
                    className="header flex flex-col items-center justify-center w-full"
                  >
                    <div className="content w-full flex flex-col items-center justify-center">
                      <span
                        className="text-[#4c57d6] text-[1rem]"
                        style={{
                          fontWeight: "600",
                          lineHeight: "1.5rem",
                          textTransform: "uppercase",
                        }}
                        id="package_name"
                      >
                        {selectedPlan.type} - {selectedPlan.package_name} Mbit
                      </span>
                      <p
                        className="message text-center"
                        data-translate="get_mst"
                      >
                        {translate("get_mst")} <br />
                        {translate("get_mst_2")} <br />
                        {translate("get_mst_3")}
                      </p>
                    </div>
                    <div className="w-full h-fit flex flex-col items-center justify-center gap-2 py-2 md:px-2 lg:px-4">
                      <div
                        className="w-full flex flex-row items-center justify-between border border-2 p-2 rounded-md cursor-pointer"
                        onClick={() => handlePackSelect(selectedPlan.price_one)}
                      >
                        <h2 data-translate="mnth_1">{translate("mnth_1")}</h2>
                        <h1>
                          <span id="price_1">{selectedPlan.price_one}</span> TL
                        </h1>
                      </div>
                      <div
                        className="w-full flex flex-row items-center justify-between border border-2 p-2 rounded-md cursor-pointer"
                        onClick={() =>
                          handlePackSelect(selectedPlan.price_three)
                        }
                      >
                        <h2 data-translate="mnth_3">{translate("mnth_3")}</h2>
                        <h1>
                          <span id="price_3">{selectedPlan.price_three}</span>{" "}
                          TL
                        </h1>
                      </div>
                      <div
                        className="w-full flex flex-row items-center justify-between border border-2 p-2 rounded-md cursor-pointer"
                        onClick={() => handlePackSelect(selectedPlan.price_six)}
                      >
                        <h2 data-translate="mnth_6">{translate("mnth_6")}</h2>
                        <h1>
                          <span id="price_6">{selectedPlan.price_six}</span> TL
                        </h1>
                      </div>
                      <div
                        className="w-full flex flex-row items-center justify-between border border-2 p-2 rounded-md cursor-pointer"
                        onClick={() =>
                          handlePackSelect(selectedPlan.price_twelve)
                        }
                      >
                        <h2 data-translate="mnth_12">{translate("mnth_12")}</h2>
                        <h1>
                          <span id="price_12">{selectedPlan.price_twelve}</span>{" "}
                          TL
                        </h1>
                      </div>
                    </div>
                    <div className="actions w-full py-[0.75rem] px-[1rem]">
                      <div
                        className="track mb-2"
                        style={{
                          display: "inline-flex",
                          marginTop: "0.75rem",
                          padding: "0.5rem 1rem",
                          color: "#242525",
                          fontSize: "1rem",
                          lineHeight: "1.5rem",
                          fontWeight: "500",
                          justifyContent: "center",
                          width: "100%",
                          borderRadius: "0.375rem",
                          border: "1px solid #D1D5DB",
                          backgroundColor: "#fff",
                          boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
                        }}
                      >
                        <h1 className="text-black font-semibold">
                          Total :{" "}
                          <span id="total" className="">
                            {totalPrice}
                          </span>{" "}
                          TL
                        </h1>
                      </div>
                      {showOptions && (
                        <div
                          id="buyButton"
                          onClick={hidePack}
                          className="w-full flex flex-row items-center justify-between gap-4"
                        >
                          <div
                            style={{
                              display: "inline-flex",
                              padding: "0.5rem 1rem",
                              fontSize: "1rem",
                              lineHeight: "1.5rem",
                              fontWeight: "500",
                              justifyContent: "center",
                              width: "100%",
                              borderRadius: "0.375rem",
                              border: "none",
                              backgroundColor: "#120851",
                              boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
                            }}
                            className="history text-white"
                            data-translate="buy_pkg"
                            previewlistener="true"
                          >
                            {translate("buy_pkg")}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </>
            </div>
          </div>
        </section>
      </div>
      <section id="packages" className="pricing-section spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-7">
              <div className="section-title normal-title">
                <h3
                  data-translate="pln_p"
                  style={{ textTransform: "none !important" }}
                >
                  {translate("pln_p")}
                </h3>
              </div>
            </div>
            <div className="col-lg-5 col-md-5">
              <div className="pricing__swipe-btn">
                {[translate("eckpk"), translate("prpk")].map((type) => (
                  <label
                    key={type}
                    onClick={() => handleFilter(type)}
                    htmlFor="inputId"
                    style={{ textTransform: "none" }}
                    className={category === type ? "active" : ""}
                  >
                    {type}
                  </label>
                ))}
              </div>
            </div>
          </div>
          {category === translate("eckpk") ? (
            <div className="row monthly__plans active">
              {iPackages
                .filter((item) => item.type === "eco")
                .sort(
                  (a, b) => parseInt(a.package_name) - parseInt(b.package_name)
                )
                .map((item, index) => (
                  <div key={index} className="col-lg-3 col-md-6 col-sm-6">
                    <div className="pricing__item">
                      <h3>
                        {item.package_name} {translate("mbs_1")}
                        <span>{translate("mbs_2")}</span>
                      </h3>
                      <ul>
                        <li className="price_tag">
                          <span className="priceOne">{item.price_one} </span> TL
                        </li>
                        <li data-translate="pay_3_1">{translate("pay_3_1")}</li>
                        <li data-translate="pay_6_2">{translate("pay_6_2")}</li>
                        <li data-translate="pay_12_3">
                          {translate("pay_12_3")}
                        </li>
                      </ul>
                      <div
                        onClick={() => hidePack(item)}
                        className="primary-btn"
                        data-translate="chze"
                      >
                        {translate("chze")}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          ) : (
            <div className="row yearly__plans active">
              {iPackages
                .filter((item) => item.type === "pro")
                .sort(
                  (a, b) => parseInt(a.package_name) - parseInt(b.package_name)
                )
                .map((item, index) => (
                  <div key={index} className="col-lg-3 col-md-6 col-sm-6">
                    <div className="pricing__item">
                      <h3>
                        {item.package_name} {translate("mbs_1")}
                        <span>{translate("mbs_2")}</span>
                      </h3>
                      <ul>
                        <li className="price_tag">
                          <span className="priceOne">{item.price_one} </span> TL{" "}
                        </li>
                        <li data-translate="pay_3_1">{translate("pay_3_1")}</li>
                        <li data-translate="pay_6_2">{translate("pay_6_2")}</li>
                        <li data-translate="pay_12_3">
                          {translate("pay_12_3")}
                        </li>
                      </ul>
                      <div
                        onClick={() => hidePack(item)}
                        className="primary-btn"
                        data-translate="chze"
                      >
                        {translate("chze")}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default SelectPackPayment;
