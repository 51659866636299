import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import axios from "axios";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
function PaymentForm({ pack }) {
  const { t: translate } = useTranslation();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);

  const linkRequest = async () => {
    if (!phoneNumber || !isValidTurkishPhoneNumber(phoneNumber)) {
      toast.error(translate("err_phone"));
      return;
    }

    try {
      setLoading(true);
      await axios.post("https://alempay-bot.onrender.com/request-link", {
        phone_number: phoneNumber,
        price: pack.price,
      });

      await emailjs.send(
        process.env.REACT_APP_EMAIL_JS_SERVICE_ID,
        process.env
          .REACT_APP_EMAIL_JS_SERVICE_ID_EMAIL_JS__CONTACT_SELF_TEMPL_ID,
        {
          refMerchantStore: 704,
          phone: phoneNumber,
          amount: pack.price,
          pack: `${pack.speed} ${pack.type}`,
          lang: "tr",
          currency: "TRY",
          message: "{Link} {Tutar}",
        }
      );
      toast.success(translate("scss_link"));
      setLoading(false);
      setTimeout(function () {
        window.location.href = "/";
      }, 3000);
    } catch (error) {
      toast.error(translate("errr_link"));
      setLoading(false);
    }
  };

  const isValidTurkishPhoneNumber = (number) => {
    const regex = /^(?:\+90|0)?\s?\d{10}$/;
    return regex.test(number.replace(/\s+/g, ""));
  };

  return (
    <div className="lg:w-[100%] flex flex-col items-center">
      <div className="contact-form spad w-[90%]">
        <div className="container">
          <div className="col-lg-12">
            <h3>{translate("online_payment")}</h3>
            {!loading ? (
              <div className="master-container">
                <div className="card coupons">
                  <label className="title" style={{ fontSize: "20px" }}>
                    {translate("phone_num")}
                  </label>
                  <form className="form">
                    <input
                      type="text"
                      placeholder={translate("ent_phone_number")}
                      className="input_field"
                      onChange={(event) => setPhoneNumber(event.target.value)}
                      value={phoneNumber}
                    />
                  </form>
                </div>
                <div className="card checkout">
                  <label className="title" style={{ fontSize: "20px" }}>
                    {translate("pck_details")}
                  </label>
                  <div className="details">
                    <span style={{ fontSize: "20px" }}>
                      {translate("pck_name")}
                    </span>
                    <span style={{ textTransform: "uppercase" }}>
                      {pack?.speed}
                    </span>
                    <span style={{ fontSize: "20px" }}>
                      {translate("pck_type")}
                    </span>
                    <span style={{ textTransform: "uppercase" }}>
                      {pack?.type}
                    </span>
                    <span style={{ fontSize: "20px" }}>
                      {translate("pck_price")}
                    </span>
                    <span>TRY {pack?.price}</span>
                  </div>
                  <div className="checkout--footer">
                    <label className="price">
                      <sup>TRY </sup>
                      {pack?.price}
                    </label>
                    <button
                      onClick={linkRequest}
                      className="checkout-btn"
                      style={{ fontSize: "20px" }}
                    >
                      {translate("checkout")}
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="w-full flex justify-center container">
                <div className="spinner">
                  <div className="spinnerin"></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentForm;
