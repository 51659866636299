import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";
import { useTranslation } from "react-i18next";
import PaymentForm from "../components/PaymentForm";
import SelectPackPayment from "../components/SelectPackPayment";

function Payment({ handleHidePack }) {
  const { t: translate } = useTranslation();
  const [pack, setPack] = useState([]);
  const [showPackages, setShowPackages] = useState(false);
  useEffect(() => {
    setPack(JSON.parse(localStorage.getItem("selected_package")));

    if (pack.length < 0) {
      setShowPackages(true);
    }
  }, []);
  useEffect(() => {
    emailjs.init({
      publicKey: process.env.REACT_APP_EMAIL_JS_PUBLIC_KEY,
      limitRate: {
        id: "app",
        throttle: 60000,
      },
    });
  }, []);

  return (
    <div className="mt-[5rem] md:mt-[6rem]">
      <div className="breadcrumb-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrumb__option">
                <Link data-translate="home_txt" to="/">
                  <span className="fa fa-home"></span> {translate("home_txt")}
                </Link>
                <span data-translate="payment_link">
                  {translate("payment_link")}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showPackages ? (
        <SelectPackPayment
          setShowPackages={setShowPackages}
          handleHidePack={handleHidePack}
        />
      ) : (
        <PaymentForm pack={pack} />
      )}
    </div>
  );
}

export default Payment;
